/** @format */

.loading {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
}
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background-color: #ccc;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
}
input[type="checkbox"]:checked + label {
	background-color: #111;
}
select {
	width: 120px;
	height: 40px;
	padding: 0.8em 0.8em;
	border: 1px solid #eee;
	font-family: inherit;
	background: url("../../images/arrow.png") no-repeat 95% 50%;
	box-sizing: border-box;
	border-radius: 5px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select::-ms-expand {
	display: none;
}

.modal-shadow {
	font-family: "Apple SD Gothic Neo";
	position: fixed;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	.modal {
		width: 320px;
		min-height: 160px;
		background-color: #fff;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		font-family: "Apple SD Gothic Neo";
		&-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 26px;
			color: #373c47;
			margin-top: 38px;
			margin-bottom: 16px;
		}
		&-content {
			font-size: 14px;
			color: #9ea2a8;
			margin-bottom: 36px;
			padding: 0 20px;
		}
		&-actions {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 320px;
			div {
				height: 56px;
				text-align: center;
				line-height: 56px;
				cursor: pointer;
			}
			&-close {
				width: 50%;
				color: #545861;
				background: #eeeeee;
				border-radius: 0px 0px 0px 10px;
			}
			&-confirm {
				width: 50%;
				color: #ffffff;
				background: #111111;
				border-radius: 0px 0px 10px 0px;
			}
			&-alert {
				width: 320px;
				color: #545861;
				background: #eeeeee;
				border-radius: 0px 0px 10px 10px;
			}
		}
	}
	.modal-file {
		width: 420px;
		min-height: 122px;
		background-color: #fff;
		border-radius: 10px;
		font-size: 14px;
		font-family: "Apple SD Gothic Neo";
		&-title {
			padding: 24px;
			color: #373c47;
			font-weight: bold;
		}
		&-input {
			display: flex;
			padding: 0 24px 24px 24px;
			&-text {
				width: 282px;
				height: 44px;
				background: #f6f6f6;
				border: 1px solid #eeeeee;
				padding-left: 16px;
				color: #9ea2a8;
				border-radius: 5px 0 0 5px;
				cursor: pointer;
			}
			&-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				width: 90px;
				height: 48px;
				background: #111111;
				border-radius: 0 5px 5px 0;
				color: #ffffff;
				cursor: pointer;
			}
		}
	}
	.modal-save {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 372px;
		height: 498px;
		background-color: #fff;
		border-radius: 10px;
		font-size: 14px;
		font-family: "Apple SD Gothic Neo";
		&-grid {
			display: flex;
			flex-wrap: wrap;
			&-name {
				min-width: 100%;
				height: 18px;
				padding: 32px 0 6px 20px;
				font-weight: bold;
				color: #545861;
			}
			&-input {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-top: 4px;
				input {
					width: 100%;
					height: 40px;
					background: #fff;
					border: 1px solid #eeeeee;
					padding-left: 16px;
					box-sizing: border-box;
					margin: 0 20px;
				}
			}
			&-page {
				width: 100%;
				padding-left: 20px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				.checkbox-label {
					width: 55px;
				}
				.selectionRange {
					width: 156px;
					height: 40px;
					// background: #f6f6f6;
					border: 1px solid #eeeeee;
					box-sizing: border-box;
					padding-left: 16px;
					color: #9ea2a8;
					border-radius: 5px;
					margin-right: 20px;
				}
			}
			&-half {
				padding: 0 20px;
				&:last-child {
					padding-left: 0;
				}
				.modal-save-grid-name {
					padding-left: 0;
				}
				select {
					//margin-left: 20px;
				}
				.input-save {
					width: 192px;
					height: 40px;
					// background: #f6f6f6;
					border: 1px solid #eeeeee;
					box-sizing: border-box;
					border-radius: 5px;
					color: #9ea2a8;
					padding-left: 16px;
				}
			}
			.modal-save-route {
				display: flex;
				padding: 0 20px;
				&-input {
					width: 232px;
					height: 40px;
					padding-left: 16px;
					background: #f6f6f6;
					border: 1px solid #eeeeee;
					box-sizing: border-box;
					border-radius: 5px 0 0 5px;
					color: #9ea2a8;
				}
				&-input-btn {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100px;
					height: 40px;
					background: #545861;
					border-radius: 5px;
					font-weight: bold;
					color: #fff;
					cursor: pointer;
				}
			}
		}
		.total-page {
			display: flex;
			justify-content: flex-end;
			margin-top: 24px;
			margin-bottom: 32px;
			&-title {
				color: #737780;
				margin-right: 12px;
			}
			&-number {
				color: #373c47;
				font-weight: bold;
				margin-right: 20px;
			}
		}
		&-actions {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			div {
				height: 56px;
				text-align: center;
				line-height: 56px;
				cursor: pointer;
				font-size: 16;
				font-weight: bold;
			}
		}
	}
}
