/** @format */
.Main {
	display: flex;
}
.sideBar {
	position: fixed;
	width: 200px;
	min-height: 100vh;
	max-height: 100%;
	background-color: #373c47;
	display: flex;
	flex-direction: column;
	align-items: center;
	.list {
		font-size: 14px;
		width: 168px;
		height: 44px;
		color: #9ea2a8;
		margin-bottom: 20px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		&:first-child {
			margin-top: 54px;
		}
		.img {
			background-image: url("../../images/main/workbook.svg");
			background-repeat: no-repeat;
			width: 20px;
			height: 20px;
			margin-left: 22px;
			margin-right: 13px;
		}
		&:hover,
		&.activate {
			background: #0bcea5;
			border-radius: 10px;
			color: #fff;
			.img {
				background-image: url("../../images/main/bookmark.svg");
			}
		}
	}
}
input[type="file"] {
	display: none;
}
.container {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	width: calc(100vw - 200px);
	min-height: 100vh;
	max-height: 100%;
	background-color: #545861;
	padding-left: 20px;
	margin-left: 200px;
	.createFile {
		cursor: pointer;
	}
	&-card {
		width: 200px;
		height: 326px;
		background: #ffffff;
		border-radius: 10px;
		margin: 20px;
		position: relative;
		& > div > img {
			width: 200px;
			height: 285px;
			object-fit: cover;
			border-radius: 10px 10px 0 0;
		}
		&-shadow {
			position: absolute;
			width: 202px;
			height: 285px;
			top: -1px;
			left: -1px;
			background: rgba(13, 16, 26, 0.7);
			border-radius: 10px;
			opacity: 0;
			&:hover {
				opacity: 1;
				cursor: pointer;
			}
			.iocnGraup {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				div {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					margin: 10px 20px;
				}
				.description {
					background: #0bcea5;
					&:hover {
						background: #0c4944;
					}
				}
				.delete {
					background: #9ea2a8;
					&:hover {
						background: #383c45;
					}
				}
			}
		}
		.plus {
			width: 72px;
			height: 72px;
			&::before,
			&::after {
				position: absolute;
				top: 127px;
				left: 100px;
				content: "";
				height: 72px;
				width: 2px;
				background-color: #ccc;
				cursor: pointer;
			}
			&::before {
				transform: rotate(90deg);
			}
		}
		&-title {
			display: flex;
			align-items: center;
			width: 200px;
			height: 40px;
			font-size: 14px;
			font-weight: bold;
			flex-grow: 1;

			input {
				width: 200px;
				height: 40px;
				padding-left: 10px;
				border: 0;
				border-radius: 0 0 10px 10px;
				background-color: transparent;
			}
		}
	}
}
